import React, {useRef} from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_dajii5q', 'template_118567r', form.current, {
            publicKey: 'SF85KpcGWEdQ0DWVx',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
        };

    return (
        <div className="join" id="join-us">
            <div className="left-j">
                <hr/>
                <div>
                    <span className="stroke-text">Ready To</span>
                    <span> Level Up</span>
                </div>
                <div>
                    <span>Your Body </span>
                    <span className="stroke-text">With us?</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} action="" className="email-container" onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder="Enter your name"/>
                    <button className="btn btn-j">Join Now</button>
                </form>
            </div>
        </div>
    )
}

export default Join
