import React, {useState} from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import {Link} from 'react-scroll'

const Header = () => {

    const [menuOpened, setMenuOpened] = useState(false)
    const mobile = window.innerWidth <= 768? true : false
    console.log(menuOpened, mobile)
    return (
        <div className="header">
            <img src={Logo} alt="" className="logo" />
            {menuOpened == false && mobile == true  ?  (
               <div onClick={() => setMenuOpened(true)} style={{backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px'}}>
               <img src={Bars} alt="" style={{width: '1.5rem', height: '1.5rem'}} />
            </div>
           )
            : (
            <ul className="header-menu">
                    <li onClick={() => setMenuOpened(false)}><Link to="Home" span={true} smooth={true} activeClass="active" >Home</Link></li>
                    <li onClick={() => setMenuOpened(false)}><Link>Programs</Link></li>
                    <li onClick={() => setMenuOpened(false)}><Link>Why us</Link></li>
                    <li onClick={() => setMenuOpened(false)}><Link>Plans</Link></li>
                    <li onClick={() => setMenuOpened(false)}><Link to="testimonials" span={true} smooth={true}>Testimonials</Link></li>
            </ul>
            )}
        </div>
    )
}

export default Header
